import React, { useState } from 'react';
import { string, func } from 'prop-types';
import Title from '../Title';
import TextField from '@mui/material/TextField';
import { Button, Box } from '@mui/material';
import CtaPrimary from '../Buttons/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Link } from 'gatsby';

const specialLabel = () => {
    return (
        <>
            I hereby consent to the processing of the personal data that I have
            provided and declare my agreement with the data protection
            regulations in the data privacy statement{' '}
            <Link className="text-white" to="/privacy-and-cookies-policy">
                here
            </Link>
        </>
    );
};

const ApplyForm = (props) => {
    const { className } = props;
    let schema = Yup.object({
        name: Yup.string().required('A name is required'),
        desiredPosition: Yup.string().required(
            'Please tell us what positions you desire',
        ),
        email: Yup.string().email('Invalid email format').required('Required'),
        resume: Yup.mixed().required('Resume is required'),
    });
    const [state, setState] = useState({
        name: '',
        desiredPosition: '',
        email: '',
        resume: '',
    });
    const [filesState, setFilesState] = useState({
        resume: undefined,
        attachment: undefined,
    });

    const [checked, setGdprCheck] = useState(false);
    const [errorList, setErrors] = useState({
        name: '',
        desiredPosition: '',
        email: '',
        resume: '',
    });
    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleFileChange = (e) => {
        const target = e.target;
        const name = target.name;
        const file = target.files[0];

        setFilesState((prevState) => ({
            ...prevState,
            [name]: file,
        }));
    };
    const handleCheck = () => {
        setGdprCheck((prevState) => !prevState);
    };
    const handleSubmit = (event) => {
        setErrors({
            name: '',
            desiredPosition: '',
            email: '',
            attachment: '',
        });
        const mergedState = { ...state, ...filesState };

        schema
            .validate(mergedState, { abortEarly: false })
            .then(() => {
                if (!checked) {
                    toast.warn('Please agree to the GDPR terms', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    const form = document.querySelector('#careers-page-form');
                    const formData = new FormData(form);

                    fetch('/', {
                        method: 'POST',
                        body: formData,
                    }).then(() => {
                        setState({
                            name: '',
                            desiredPosition: '',
                            email: '',
                        });
                        setState({
                            resume: undefined,
                            attachment: undefined,
                        });
                        toast.success('🦄 Submitted Successfully!', {
                            position: 'top-right',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    });
                }
            })
            .catch((err) => {
                err.inner.forEach((e) => {
                    setErrors((prevState) => ({
                        ...prevState,
                        [e.path]: e.message,
                    }));
                });
            });
    };
    return (
        <form
            name="careers-page-form"
            data-netlify="true"
            className="bg-primary-300 py-12 lg:px-0 md:px-8  px-[16px] careers-form"
            id="careers-page-form"
        >
            <input
                hidden
                name="form-name"
                onChange={() => null}
                value="careers-page-form"
            />
            <Title
                level={2}
                className="text-white  text-[42px] leading-[140%]  max-w-[1440px] lg:px-[160px] lg:mx-auto mb-[12px]"
                style={{ marginBottom: 0 }}
            >
                Nothing for you?
            </Title>
            <p className="text-white font-sans text-black-500 text-[16px] xl:text-[20px]  max-w-[1440px] lg:px-[160px] lg:mx-auto mb-[60px]">
                Contact us anyway and send your resume.
            </p>
            <div className="grid md:grid-cols-2 grid-cols-1 md:gap-24 gap-12  max-w-[1440px] lg:px-[160px] lg:mx-auto">
                <TextField
                    label="Name"
                    variant="standard"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    error={!!errorList.name}
                    helperText={errorList.name}
                />
                <TextField
                    label="Position"
                    variant="standard"
                    name="desiredPosition"
                    value={state.desiredPosition}
                    onChange={handleChange}
                    error={!!errorList.desiredPosition}
                    helperText={errorList.desiredPosition}
                />
                <TextField
                    label="Email"
                    variant="standard"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                    error={!!errorList.email}
                    helperText={errorList.email}
                />
                <Box
                    style={{
                        justifyContent: 'space-between',
                        display: 'fllex',
                        flexDirection: 'row',
                        alignItems: 'start',
                    }}
                >
                    <div
                        style={{
                            width: 'calc(50% - 10px)',
                            marginRight: '20px',
                            display: 'inline-block',
                        }}
                    >
                        <Button
                            variant="outlined"
                            component="label"
                            style={{ width: '100%' }}
                        >
                            <span
                                className="text-white"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {filesState.resume?.name ?? 'Upload Resume*'}
                            </span>
                            <input
                                type="file"
                                name="resume"
                                hidden
                                accept="application/pdf"
                                onChange={handleFileChange}
                            />
                        </Button>
                        {!!errorList.resume && (
                            <p
                                className="font-sans"
                                style={{ color: '#d32f2f' }}
                            >
                                {errorList.resume}
                            </p>
                        )}
                    </div>
                    <div
                        style={{
                            width: 'calc(50% - 10px)',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                    >
                        <Button
                            variant="outlined"
                            component="label"
                            style={{ width: '100%' }}
                        >
                            <span
                                className="text-white"
                                style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {filesState.attachment?.name ??
                                    'Additional Attachment'}
                            </span>
                            <input
                                type="file"
                                name="attachment"
                                hidden
                                accept="application/pdf"
                                onChange={handleFileChange}
                            />
                        </Button>
                    </div>
                </Box>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-24 lg:px-[160px] max-w-[1440px] lg:mx-auto mt-[40px]">
                <FormGroup className="mb-[40px] md:mb-0">
                    <FormControlLabel
                        style={{ color: '#fff' }}
                        className="font-sans"
                        control={
                            <Checkbox
                                name="gdprCheck"
                                checked={checked}
                                onChange={handleCheck}
                            />
                        }
                        label={specialLabel()}
                    />
                </FormGroup>
                <div className="max-w-[100%] md:w-32 md:justify-self-end mt-0">
                    <CtaPrimary
                        className="border-box px-[43%] md:px-[30%] md:w-auto w-[100%] "
                        title="Send"
                        onClick={handleSubmit}
                    />
                </div>
            </div>
        </form>
    );
};
ApplyForm.propTypes = {
    className: string,
    handleSubmit: func,
};

ApplyForm.defaultProps = {
    className: '',
    handleSubmit: () => null,
};

export default ApplyForm;
