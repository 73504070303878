import axios from "axios";

// ========== API KEYS ========== //
const productKey = "spotonproduct";
const salesKey = "regionalspotonsales";
const corporate = "spotoncorporate";
const key = () => {
  if (typeof window !== `undefined`) {
    btoa("1bc28b82de90f4ba9f9d32858cbbd328-3:");
  }
};
// ========== API INITIALIZERS ========== //

// eslint-disable-next-line import/prefer-default-export
export const productapi = axios.create({
  baseURL: `https://boards-api.greenhouse.io/v1/boards/${productKey}/`,
  responseType: "json",
  timeout: 7500,
  headers: { Accept: "application/json" },
});

export const salesapi = axios.create({
  baseURL: `https://boards-api.greenhouse.io/v1/boards/${salesKey}/`,
  responseType: "json",
  timeout: 7500,
  headers: { Accept: "application/json" },
});

export const corpapi = axios.create({
  baseURL: `https://boards-api.greenhouse.io/v1/boards/${corporate}/`,
  responseType: "json",
  timeout: 7500,
  headers: { Accept: "application/json" },
});

export const harvestAPI = axios.create({
  baseURL: "https://harvest.greenhouse.io/v1/",
  responseType: "json",
  timeout: 7500,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: "Basic MWJjMjhiODJkZTkwZjRiYTlmOWQzMjg1OGNiYmQzMjgtMzo=",
    Accept: "application/json",
  },
});
// ========== PRODUCT API ========== //
export const getAllOffices = async () => {
  const { data: offices } = await productapi.get("offices");
  return offices;
};

export const getAllDepartments = async () => {
  const { data: departments } = await productapi.get("departments");
  return departments.departments;
};

export const getAllJobs = async () => {
  const { data: jobs } = await productapi.get("jobs?content=true");
  return jobs.jobs;
};

export const getJobsListByDepartment = async (departmentId) => {
  const { data: jobs } = await productapi.get(`departments/${departmentId}`);
  return jobs;
};

// ========== SALES API ========== //
export const getSalesDepartments = async () => {
  const { data: departments } = await salesapi.get("departments");
  return departments.departments;
};

// ========== SALES API ========== //
export const getCorporateDepartments = async () => {
  const { data: departments } = await corpapi.get("departments");
  return departments.departments;
};

export const fetchSome = async () => {
  const { data: jobs } = await harvestAPI.get("jobs");
  return jobs;
};
