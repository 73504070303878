import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import JobsSearch from './JobSearch';
import Title from '../Title';
import CtaSecondary from '../Buttons/Secondary';
import Faq from './Faq';

const checkLocation = (data) => {
    return data.location.name.toLowerCase().split(' ').includes('poland');
};

const CareersDisplay = (props) => {
    const { careersDepartments, openings } = props;
    const [productAreas, setProductAreas] = useState([]);
    const [activeArea, setActiveArea] = useState([]);
    const [activeTab, setActiveTab] = useState('product');
    const [sub, setSub] = useState(null);
    useEffect(() => {
        const executer = careersDepartments.product.map((item) => ({
            name: item.name,
            areaId: item.id,
        }));
        const prefilter = careersDepartments.product.map((sect) => sect.jobs);
        setProductAreas(executer);
        setActiveArea(prefilter.flat().filter(checkLocation));
        // getAllJobs().then((data) => console.log(data));
    }, [careersDepartments]);

    const selectedChange = (value) => {
        const filtered = careersDepartments[activeTab].filter((item) => {
            if (value === 'all') {
                return careersDepartments[activeTab]
                    .map((sect) => sect.jobs)
                    .flat();
            }
            return item.name === value;
        });
        if (value === 'all') {
            setActiveArea(filtered.map((filt) => filt.jobs).flat());
        } else {
            setActiveArea(filtered[0]?.jobs);
        }
    };

    const handleTabChange = (value) => {
        if (value === 'product' || value === 'sales' || value === 'corp') {
            const executer = careersDepartments[value].map((item) => ({
                name: item.name,
                areaId: item.id,
            }));
            const prefilter = careersDepartments[value].map(
                (sect) => sect.jobs,
            );
            setProductAreas(executer);
            setActiveArea(prefilter.flat().filter(checkLocation));
        }
        setActiveTab(value);
    };

    return (
        <section
            id="open-positions"
            className="p-[16px] md:px-8 max-w-[1440px] lg:px-[160px] 2xl:mx-auto"
        >
            <Title
                level={2}
                className="text-center md:mt-[100px] mt-[80px]  text-[42px] leading-[140%]"
                style={{ marginBottom: 24 }}
            >
                Open positions
            </Title>
            <p className="font-sans text-center text-black-200 mb-[80px] mt-0 text-[20px] leading-[160%]">
                All our positions randomly switching
            </p>
            <div>
                <JobsSearch sectionData={activeArea} />
            </div>
            <div>
                <Faq />
            </div>
            <div
                className="md:flex grid mt-16 pt-16 mb-16"
                style={{ borderTop: 'solid 1px #E4E6E9' }}
            >
                <div className="flex-1 self-center">
                    <Title
                        level={3}
                        className=" text-[32px] leading-[130%]"
                        style={{ margin: 0 }}
                    >
                        Terms of data management
                    </Title>
                </div>
                <div className="self-center md:mt-0 mt-8">
                    <CtaSecondary
                        title="Click here"
                        large
                        target="/privacy-and-cookies-policy"
                    />
                </div>
            </div>
        </section>
    );
};

CareersDisplay.propTypes = {
    careersDepartments: PropTypes.objectOf(PropTypes.any),
    openings: PropTypes.objectOf(PropTypes.any),
};
CareersDisplay.defaultProps = {
    careersDepartments: '',
    openings: {},
};

export default CareersDisplay;
