import React from 'react';
import {string, bool, any, arrayOf} from "prop-types";
import Title from "../Title";
import Blurb from "./Blurb";

const SmallFeatures = (props) => {
    const {className, benefits} = props
    return (
        <section className='p-8 px-[16px] md:px-8 pt-[54px] lg:pt-[106px] bg-black-800'>
            <Title level={2} withBlueSymbol symbol='.' className='text-center text-[42px] leading-[140%]'>Benefits</Title>
            <div className='flex flex-wrap justify-center max-w-[1440px] lg:px-[160px]  lg:mx-auto'>
                {benefits.map((benefit, idx) => (
                    <Blurb {...benefit} key={benefit.title} isLast={idx + 1 === benefits.length}/>
                ))}
            </div>
        </section>
    );
};
SmallFeatures.propTypes = {
    className: string,
    benefits: arrayOf(any)
};

SmallFeatures.defaultProps = {
    className: '',
};

export default SmallFeatures;
