/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Title from '../Title';
import CtaPrimary from '../Buttons/Button';
import chevron from '../../images/svg/chevron_left.svg';

const checkLocation = (data) => {
    return data.location.name.toLowerCase().split(' ').includes('poland');
};

const hybridPositions = ['Engineering Manager', 'Customer Service Agent'];
const onsitePositions = ['Intern', 'Helpdesk Specialist'];

const JobsSearch = (props) => {
    const { sectionData } = props;
    const Chevron = <img className="ml-2.5" alt="chevron icon" src={chevron} />;

    return (
        <div className="careers-search mt-[64px]">
            {sectionData.filter(checkLocation).map((data, idx) => (
                <div
                    key={`job_${data.title}_${idx.toString()}`}
                    className={`${
                        idx % 2 === 0 ? 'bg-black-800' : ''
                    } md:px-[43px] px-[16px] pt-[8px] md:pt-0 rounded-lg md:flex grid`}
                    style={{ paddingBottom: 32 }}
                >
                    <div className="flex-1">
                        <a
                            href={data.absolute_url}
                            target="_blank"
                            rel="noreferrer"
                            className="no-underline text-black"
                        >
                            <Title
                                className="text-[24px] leading-[130%] mt-[32px]"
                                level={3}
                            >
                                {data.title}
                            </Title>
                        </a>
                        <p className="font-sans text-[16px] leading-[160%] mb-2 mt-0">
                            {data.location.name.split(',').join(' / ')}
                        </p>
                        <span className="font-sans text-[16px] leading-[160%]">
                            Full Time
                        </span>
                        <p className="font-sans mt-1 text-[16px] mb-2 leading-[160%]">
                            {hybridPositions.some((el) =>
                                data.title.includes(el),
                            )
                                ? 'Hybrid work'
                                : onsitePositions.some((el) =>
                                      data.title.includes(el),
                                  )
                                ? 'Onsite work'
                                : 'Remote work'}
                        </p>
                    </div>
                    <div className="min-w-[100px] self-end mb-2 md:mt-0 mt-4 w-28">
                        <a
                            className="text-16 leading-[160%]  cta-p
        flex flex-row items-center
        px-6 py-2.5
        font-sans
        rounded-full border-0 cursor-pointer
        text-white font-bold bg-primary hover:bg-opacity-70 no-underline"
                            href={data.absolute_url}
                        >
                            Apply {Chevron}
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
};

JobsSearch.propTypes = {
    sectionData: PropTypes.arrayOf(PropTypes.any),
};
JobsSearch.defaultProps = {
    sectionData: [{ title: 'loading' }],
};

export default JobsSearch;
