import React from 'react';
import PropTypes, { string, bool, oneOf, arrayOf, objectOf } from 'prop-types';
import Title from '../Title';
import Image from '../ImageQueries/GeneralImages';
import check from '../../images/svg/checkmark.svg';

const SplitHero = (props) => {
    const { className, list, careersImg } = props;
    return (
        <div className="grid lg:grid-cols-2 grid-cols-1 min-h-[450px] lg:max-h-[1300px] 2xl:max-h-[1300px] max-h-auto overflow-hidden">
            <div className="border-box max-h-[1300px] h-auto object-fill w-full">
                <Image
                    imageName={careersImg[0]}
                    className="object-fill h-full w-full"
                />
            </div>
            <div
                className="bg-black md:p-24 md:px-8 lg:pt-[80px] lg:px-[115px] p-8 pb-[60px] 2xl:pb-0 mb-0"
                style={{ zIndex: 9 }}
            >
                <Title
                    level={2}
                    withBlueSymbol
                    symbol="?"
                    className="text-white max-w-[475px] text-[42px] leading-[130%]"
                    style={{ marginBottom: 24, marginTop: 0 }}
                >
                    Why work with us
                </Title>
                <p className="font-sans text-black-500 text-[16px] leading-[160%] mb-[8px] max-w-[475px] mt-0">
                    At Spoton we’re always open to cooperating with talented
                    developers. As an experienced team of senior programmers,
                    we’re currently offering positions also to mid & junior
                    developers and interns.
                </p>
                {list.length > 0 && (
                    <ul className="list-none p-0 max-w-[475px]">
                        {list.map((item, i) => (
                            <li
                                className="flex items-center"
                                key={`${i.toString()}__list`}
                            >
                                <img
                                    className="flex-initial mr-4"
                                    src={check}
                                    alt=""
                                />
                                <p className="flex-grow self-center text-white font-bold font-sans text-[18px] leading-[130%]">
                                    {item}
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};
SplitHero.propTypes = {
    className: string,
    careersImg: arrayOf(PropTypes.any),
    list: arrayOf(PropTypes.any),
};

SplitHero.defaultProps = {
    className: '',
    careersImg: [],
    list: [],
};

export default SplitHero;
