import React from 'react';
import {string, bool, oneOf, objectOf, any} from "prop-types";
import Image from "../ImageQueries/GeneralImages";
import Title from "../Title";

const Blurb = (props) => {
    const {img, title, subtext, isLast} = props
    return (
        <div className={`px-0 md:px-[12px] xl:px-[33px] xl:flex-[33%] md:flex-[50%] flex-[100%] flex-grow flex-shrink ${isLast ? 'mb-8 xl:max-w-[33%]' : 'mb-16'}`} >
            <Image className='w-[40px] h-[40px] mx-auto' imageName={img} />
            <Title className='text-center text-[24px] leading-[130%] ' style={{ marginBottom: 0, marginTop: 16}} level={4}>{title}</Title>
            <p className='font-sans text-black-300 text-[16px] leading-[160%] mb-[40px] text-center'>{subtext}</p>
        </div>
    );
};
Blurb.propTypes = {
    img: objectOf(any),
    title: string,
    subtext: string,
    isLast: bool,
};

Blurb.defaultProps = {
    img: '',
    title: '',
    subtext: '',
    isLast: false
};

export default Blurb;
