import React, { useState, useEffect } from 'react';
import Layout from '../Components/Layout';
import HeadingDirective from '../Components/HeadingDirective';
import SplitHero from '../Components/Hero/SplitHero';
import { careersHeroList, careerBenefits } from '../data/careers-data';
import Title from '../Components/Title';
import Image from '../Components/ImageQueries/GeneralImages';
import SmallFeatures from '../Components/SmallFeatures';
import ApplyForm from '../Components/ContactForm/ApplyForm';
import {
    getAllDepartments,
    getSalesDepartments,
    getCorporateDepartments,
    fetchSome,
} from '../helpers/greenhouse-api';
import CareersDisplay from '../Components/Careers/CareersDisplay';
import { graphql } from 'gatsby';
import SEO from '../Components/SEO';

const Careers = ({ data }) => {
    const [departments, setDepartments] = useState({
        sales: [],
        product: [],
        corp: [],
    });
    const { frontmatter } = data.markdownRemark;
    useEffect(() => {
        getAllDepartments()
            .then((areas) => {
                const prefilter = areas.filter((d) => {
                    return d.jobs.length > 0;
                });
                setDepartments((prevState) => ({
                    ...prevState,
                    product: prefilter,
                }));
                return getSalesDepartments();
            })
            .then((corpareas) => {
                const prefilter = corpareas.filter((d) => {
                    return d.jobs.length > 0;
                });
                setDepartments((prevState) => ({
                    ...prevState,
                    corp: prefilter,
                }));
            });
    }, []);
    return (
        <Layout customFoot>
            <SEO
                title={frontmatter.seo.title}
                description={frontmatter.seo.description}
                image={`https://pl.spoton.com/assets/${frontmatter.seo.image}`}
            />
            <HeadingDirective
                className="pb-0"
                link="#open-positions"
                showCircle
                btntitle="Check our vacancies"
                imgClass="top-[447px] w-[130px]"
                {...frontmatter.directive}
            />
            <SplitHero
                careersImg={frontmatter.careersImg}
                list={frontmatter.careersHeroList}
            />
            <SmallFeatures benefits={frontmatter.careerBenefits} />
            <section className="md:mt-[72px] mt-24">
                <div className="max-w-[1440px] 2xl:mx-auto px-4 lg:px-0">
                    <Title
                        level={2}
                        withBlueSymbol
                        symbol={frontmatter.joinUs?.joinUsSymbol}
                        className="text-center lg:mx-auto text-[42px] leading-[140%]"
                        style={{ marginBottom: 24 }}
                    >
                        {frontmatter.joinUs?.joinUsTitle}
                    </Title>
                    <p className="font-sans text-lg text-black-200 text-[16px] leading-[160%] text-center md:mb-[87px] mb-24  max-w-[877px] lg:mx-auto mt-0">
                        {frontmatter.joinUs?.joinUsSubtext}
                    </p>
                </div>
                <Image
                    imageName={frontmatter.joinUs?.joinUsImg}
                    className="max-h-[700px] min-h-[400px] object-fit "
                />
            </section>
            <CareersDisplay careersDepartments={departments} />
            <ApplyForm />
        </Layout>
    );
};

export default Careers;

export const pageQuery = graphql`
    query Careers {
        markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
            frontmatter {
                directive {
                    directive
                    symbol
                    subtext
                }
                seo {
                    title
                    description
                    image
                }
                careersImg {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: WEBP
                            layout: FULL_WIDTH
                        )
                    }
                }
                careersHeroList
                careerBenefits {
                    img {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                    title
                    subtext
                }
                joinUs {
                    joinUsTitle
                    joinUsSymbol
                    joinUsSubtext
                    joinUsImg {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                formats: WEBP
                                layout: FULL_WIDTH
                            )
                        }
                    }
                }
            }
        }
    }
`;
